<template>
  <div class="page" v-if="detail">
    <div class="top">
      <section v-if="detail">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/found' }">发现</el-breadcrumb-item>
          <el-breadcrumb-item>问答</el-breadcrumb-item>
        </el-breadcrumb>
        <span class="title">{{ detail.info.title }}</span>
        <div class="sectionTime">
          <span class="timeName">{{ detail.info.doctor.name }}</span>
          <span>{{ detail.info.create_time | timeFormat }}</span>
        </div>
        <div class="content">
          {{ detail.info.content }}
        </div>
        <div class="content-imgone" v-if="detail.info.images.length == 1">
          <img
            @click="imgView(detail.info.images[0])"
            :src="detail.info.images[0]"
            alt=""
          />
        </div>

        <div class="content-imgtwo" v-if="detail.info.images.length > 1">
          <img
            @click="imgView(it)"
            :src="it"
            v-for="(it, i) in detail.info.images"
            :key="i"
            alt=""
          />
        </div>

        <div class="answer">
          <el-input
            autocomplete="off"
            type="textarea"
            :rows="3"
            v-model="content"
            placeholder="请输入回答"
            class="describe"
          >
          </el-input>
          <!-- 上传按钮 -->
          <div class="bottomBox">
            <el-upload
              :file-list="fileList"
              :limit="9"
              multiple
              ref="upLoadImg"
              :action="$http.baseURL + '/dkt/other/upload'"
              name="files"
              :on-success="succseeFile"
              list-type="picture-card"
            >
              <i
                slot="default"
                class="el-icon-plus"
                v-if="fileList.length != 9"
              >
              </i>
              <i slot="default" class="el-icon-warning" v-else> </i>
              <span>{{
                fileList.length != 9 ? "配图（选填）" : "最多9张图"
              }}</span>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>

            <div class="tooltip" v-if="tooltip && fileList.length < 3">
              <div class="tooltipOne">
                <img class="question" src="@image/question.png" />
                <span>这样的提问能获得更多回答和关注</span>
                <img
                  @click="tooltip = false"
                  class="close"
                  src="@image/close.png"
                />
              </div>
              <span>1.开放的：无固定答案、有讨论价值的</span>
              <span>2.普适的：对更多人有帮助的</span>
              <span>3.简练的：简洁清晰、语句通顺的</span>
            </div>
            <span @click="findAnswer" class="release">发布</span>
          </div>
        </div>
      </section>
    </div>

    <!-- 全部回答 -->
    <div class="box">
      <section>
        <span class="boxTitle">全部回答（{{ detail.answer.length }}）</span>
        <div v-if="detail.answer.length">
          <div
            class="group"
            style="cursor: pointer"
            v-for="(item, index) in detail.answer"
            :key="index"
            @click="
              xin.goUrl(
                '/found-qa-details?id=' + item.id + '&did=' + $route.query.id
              )
            "
          >
            <!-- 个人信息 -->
            <div class="personage">
              <img :src="item.doctor.photo" />
              <div class="personage-right">
                <div class="one">
                  <span class="title">{{ item.doctor.name }}</span>
                  <span class="position">{{ item.doctor.title_name }}</span>
                  <template v-if="item.doctor.user_id != userInfo.id">
                    <span
                      class="button nullBtn"
                      v-if="item.attention"
                      @click.stop="postDiscoverAttention(item)"
                      >已关注</span
                    >
                    <span
                      class="button"
                      v-else
                      @click.stop="postDiscoverAttention(item)"
                      >+关注</span
                    >
                  </template>
                </div>
                <div class="two">
                  <span class="site">{{ item.doctor.company }}</span>
                  <span>{{ item.doctor.department_name }}</span>
                </div>
              </div>
            </div>
            <span class="subTitle">{{ item.content }}</span>
            <!-- <img class="ultra" src="@image/bc.jpg" /> -->

            <div class="content-imgtwo" v-if="item.images">
              <img
                @click.stop="imgView(it)"
                :src="it"
                v-for="(it, i) in JSON.parse(item.images)"
                :key="i"
                alt=""
              />
            </div>

            <!-- <div class="seeDetails">
							<div class="model_content">
								<p class="model_text">
									脉络丛囊肿一般在孕16-22周时，超声检查发现的胎儿侧脑室脉络丛散在的小囊肿，可以单侧或者双侧，可以单个或多个，可大可小，有的是圆形，有的是椭圆形，直径≥2mm，典型者直径介于5-20mm之间，90%以上胎儿脉络丛囊肿在妊娠26周消失，仅少数胎儿会随着孕周增长而逐渐增大，一般胎儿在妊娠18周之前都会出现脉络丛囊肿，如果囊肿出现后，没有发现胎儿其他畸形（如心脏畸形），做唐氏筛查或无创DNA检查，没有发现18三体，21三体综合征的时候，这是单纯的脉络丛囊肿，如果囊肿小于10mm，而且会越来越小，甚至消失，这样的孩子都是正常的，单纯的脉络丛囊肿，即使在孕期没有消失
								</p>
								<span class="model_detail">...<span class="more_detail">更多详情</span></span>
							</div>
						</div> -->
            <div class="detailsTime">
              <span>1.3</span>
              <span class="pageviews">万浏览</span>
              <span>3小时前</span>
            </div>
          </div>
        </div>
        <div v-else class="nullPage">暂无相关数据</div>
      </section>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="imgViewUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      detail: "",
      imgViewUrl: "",
      dialogVisible: false,
      tooltip: true,
      fileList: [],
      requerst: true, // 是否在请求状态
      content: "",
      particulars: [],
      picture: [],
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  created() {
    this.findDetail();
  },
  methods: {
    // 关注
    async postDiscoverAttention(item) {
      // 防止多次发送请求
      if (this.requerst) {
        this.requerst = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.user_id,
        });
        if (res.code == 200) {
          if (item.attention) {
            this.$message({ message: "已取消关注", type: "success" });
          } else {
            this.$message({ message: "关注成功", type: "success" });
          }
          for (let i in this.detail.answer) {
            if (item.doctor.id == this.detail.answer[i].doctor.id) {
              this.detail.answer[i].attention =
                !this.detail.answer[i].attention;
            }
          }
        }
        this.requerst = true;
      }
    },

    // 图片预览
    imgView(url) {
      this.dialogVisible = true;
      this.imgViewUrl = url;
    },

    // 发现详情
    async findDetail() {
      this.xin.isLoadding(true);
      let res = await this.$findApi.findDetail({
        discover_id: this.$route.query.id,
      });
      this.xin.isLoadding(false);
      if (res.code == 200) {
        let data = res.data || [];
        if (data.info && data.info.images) {
          data.info.images = JSON.parse(data.info.images);
        } else {
          data.info.images = [];
        }
        this.detail = data;
      }
    },

    // 文件上传
    async succseeFile(response, file, fileList) {
      if (response.code == 200) {
        this.fileList.push({
          url: response.data,
          uid: file.uid,
        });
      }
    },

    // 问答
    async findAnswer() {
      if (!this.content) {
        return this.$message.error("回复内容不能为空");
      }

      let data = {
        discover_id: this.$route.query.id,
        content: this.content,
      };
      if (this.fileList.length) {
        let arr = [];
        for (let i in this.fileList) {
          arr.push(this.fileList[i].url);
        }
        data.images = JSON.stringify(arr);
      }
      if (this.requerst) {
        this.requerst = false;
        let res = await this.$findApi.findAnswer(data);
        this.requerst = true;
        if (res.code == 200) {
          this.fileList = [];
          this.content = "";
          this.$message.success("回复成功!");
          this.findDetail();
        }
      }
    },

    //删除
    handleRemove(file) {
      this.$refs.upLoadImg.handleRemove(file);
      for (let i in this.fileList) {
        if (this.fileList[i].uid == file.uid) {
          this.fileList.splice(i, 1);
          break;
        }
      }
    },

    //查看
    handlePictureCardPreview(file) {
      this.imgViewUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .el-upload--picture-card {
    line-height: 176px;
    color: #8f8f8f;
    i {
      font-size: 30px;
      position: absolute;
      left: 50%;
      top: 40px;
      margin-left: -15px;
      > span {
        margin-top: 50px;
      }
    }
    &:hover {
      border: 1px dashed $--themeColor;
      color: $--themeColor;
      i {
        color: $--themeColor;
      }
    }

    .el-upload-list__item-thumbnail {
      width: 148px;
      height: 148px;
      object-fit: cover;
    }
  }

  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 148px;
    height: 148px;
    object-fit: cover;
  }
}

.describe {
  /deep/.el-textarea__inner {
    font-size: 14px;
    width: 100%;
    height: 100px;
    background: #f2f2f2;
    border: none;
    padding: 0;
  }
}
.content-imgone {
  margin-top: 10px;
  img {
    width: 100%;
    height: 390px;
    object-fit: cover;
    border-radius: 10px;
  }
}
.content-imgtwo {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  img {
    box-sizing: border-box;
    width: 270px;
    height: 130px;
    object-fit: cover;
    margin: 10px;
    border-radius: 10px;
  }
}
.page {
  background-color: #f2f2f2;
  > .top {
    background-color: white;
    .content {
      margin-top: 30px;
      font-size: 18px;
      color: #8f8f8f;
      line-height: 1.8;
    }
    > section {
      display: flex;
      flex-direction: column;
      > .title {
        font-size: 24px;
        color: #333333;
      }
      > .sectionTime {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        color: #8f8f8f;
        margin-top: 16px;
        > .timeName {
          margin-right: 10px;
        }
      }
      .answer {
        background: #f2f2f2;
        margin: 40px 0 30px 0;
        padding: 30px;
        padding-bottom: 0;
        width: 1200px;
        height: auto;
        box-sizing: border-box;
        .bottomBox {
          display: flex;
          margin-bottom: 40px;
          margin-top: 30px;
          > .tooltip {
            display: flex;
            flex-direction: column;
            width: 500px;
            height: 162px;
            background: #fafafa;
            border-radius: 10px;
            margin-left: 30px;
            padding: 30px;
            box-sizing: border-box;
            font-size: 14px;
            color: #8f8f8f;
            > span {
              &:last-child {
                margin-bottom: 0;
              }
              margin-bottom: 10px;
              margin-left: 24px;
            }
            > .tooltipOne {
              display: flex;
              flex-direction: row;
              > span {
                margin-bottom: 10px;
              }
              > .question {
                width: 14px;
                height: 14px;
                object-fit: cover;
                margin-right: 10px;
              }
              > .close {
                cursor: pointer;
                margin-left: auto;
                width: 16px;
                height: 16px;
              }
            }
          }
          > .release {
            cursor: pointer;
            color: #ffffff;
            font-size: 12px;
            position: absolute;
            text-align: center;
            line-height: 25px;
            width: 56px;
            height: 25px;
            background: $--themeColor;
            border-radius: 5px;
            right: 0px;
            bottom: 0px;
          }
        }
      }
    }
  }
  > .box {
    background: #f2f2f2;
    padding: 38px 0;
    box-sizing: border-box;
    > section {
      display: flex;
      flex-direction: column;
      .nullPage {
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #8f8f8f;
      }
      > .boxTitle {
        font-size: 30px;
        color: #333333;
        background: #fff;
        padding: 30px 0 0 30px;
      }
      .group {
        background: #ffffff;
        padding: 20px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        .personage {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
          }
          .personage-right {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            width: 100%;
            .one {
              display: flex;
              flex-direction: row;
              color: #333333;
              font-size: 24px;
              > .position {
                font-size: 13px;
                color: $--themeColor;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 24px;
                margin-left: 12px;
                padding: 0 12px;
                box-sizing: border-box;
              }
              .button {
                cursor: pointer;
                font-size: 12px;
                width: 56px;
                height: 25px;
                color: #ffffff;
                background: $--themeColor;
                border-radius: 5px;
                text-align: center;
                line-height: 24px;
                margin-left: auto;
              }
              .nullBtn {
                color: #8f8f8f !important;
                background: #ffffff !important;
                border: 1px solid #d9d9d9 !important;
              }
            }
            .two {
              display: flex;
              flex-direction: row;
              color: #8f8f8f;
              font-size: 16px;
              margin-top: 9px;
              > .site {
                margin-right: 20px;
              }
            }
          }
        }
        > .subTitle {
          font-size: 16px;
          color: #333333;
          line-height: 1.8;
        }
        > .ultra {
          width: 1140px;
          height: 556px;
          object-fit: cover;
          margin: 20px 0;
        }
        > .seeDetails {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: #333333;
          line-height: 1.8;
          position: relative;
          .model_content {
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            .model_text {
              font-size: 16px;
              color: #657180;
              text-align: justify;
              line-height: 1.8;
            }
            .model_detail {
              width: 5em;
              position: absolute;
              right: 0px;
              bottom: 0;
              background: #fff;
              font-size: 16px;
              color: #657180;
            }
            .more_detail {
              position: absolute;
              right: 0;
              color: $--themeColor;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
        > .detailsTime {
          display: flex;
          flex-direction: row;
          color: #8f8f8f;
          font-size: 16px;
          margin-top: 20px;
          > .pageviews {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
